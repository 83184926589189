.about-section {
  padding: 6rem 0;
  background: linear-gradient(135deg, rgba(26,26,26,0.95), rgba(40,44,52,0.95));
  min-height: 100vh;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(97, 218, 251, 0.2);
}

.about-header {
  text-align: center;
  margin-bottom: 3rem;
}

.about-header h1 {
  font-size: 3.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.about-subtitle {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.team-card {
  display: flex;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  border: 1px solid rgba(97, 218, 251, 0.2);
  overflow: hidden;
  margin-bottom: 4rem;
}

.team-left {
  width: 300px;
  padding: 2rem;
  background: rgba(97, 218, 251, 0.03);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.team-image-wrapper {
  width: 180px;
  height: 180px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(97, 218, 251, 0.2), transparent);
  border-radius: 20px;
}

.team-right {
  flex: 1;
  padding: 2rem 3rem;
  display: flex;
  align-items: flex-start;
}

.team-info {
  max-width: 600px;
}

.team-info h2 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin: 0 0 0.5rem 0;
}

.team-info h3 {
  font-size: 1.6rem;
  color: #fff;
  opacity: 0.9;
  margin: 0 0 1rem 0;
}

.team-info p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.values-section {
  margin-top: 3rem;
}

.values-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.value-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(97, 218, 251, 0.2);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.value-card:hover {
  transform: translateY(-10px);
  border-color: var(--primary-color);
}

.value-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.value-card h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

.value-card p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .about-container {
    padding: 2rem;
  }

  .about-header h1 {
    font-size: 2.5rem;
  }

  .team-card {
    flex-direction: column;
    align-items: center;
  }

  .team-left {
    width: 100%;
    padding: 2rem 2rem 1rem 2rem;
  }

  .team-right {
    padding: 1rem 2rem 2rem 2rem;
  }

  .team-info {
    text-align: center;
  }

  .team-info h2,
  .team-info h3,
  .team-info p {
    text-align: center;
  }

  .values-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}