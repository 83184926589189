.contact-section {
  padding: 6rem 0;
  background: linear-gradient(135deg, rgba(26,26,26,0.95), rgba(40,44,52,0.95));
  min-height: 100vh;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(97, 218, 251, 0.2);
}

.contact-info {
  padding: 2rem;
}

.contact-info h1 {
  font-size: 3.5rem;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
}

.contact-info p {
  font-size: 1.2rem;
  margin-bottom: 3rem;
  color: rgba(255, 255, 255, 0.8);
}

.contact-form {
  padding: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(97, 218, 251, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 10px rgba(97, 218, 251, 0.2);
}

.submit-button {
  background: var(--gradient-1);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(97, 218, 251, 0.3);
}

@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  .contact-info h1 {
    font-size: 2.5rem;
  }

  .contact-info,
  .contact-form {
    padding: 1rem;
  }
}

.contact-cards {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}

.contact-card {
  width: 290px;
  background: rgba(255, 255, 255, 0.03);
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(97, 218, 251, 0.1);
}

.contact-card:hover {
  transform: translateY(-5px);
  border-color: rgba(97, 218, 251, 0.3);
}

.contact-card .card-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.contact-card h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.contact-card p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-card .card-glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(97, 218, 251, 0.1) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.contact-card:hover .card-glow {
  opacity: 1;
}

@media (max-width: 768px) {
  .contact-cards {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-card {
    padding: 1.5rem;
  }
}