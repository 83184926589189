:root {
  --primary-color: #61dafb;
  --secondary-color: #282c34;
  --text-color: #ffffff;
  --gradient-1: linear-gradient(135deg, #61dafb 0%, #0088cc 100%);
}

.home-content {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.hero-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 6rem;
}

.animated-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #1a1a1a, #282c34);
  z-index: 1;
  overflow: hidden;
}

.glow-effect {
  position: absolute;
  width: 800px;
  height: 800px;
  background: radial-gradient(
    circle,
    rgba(97, 218, 251, 0.15) 0%,
    rgba(97, 218, 251, 0.05) 30%,
    transparent 70%
  );
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.1s ease;
}

.hero-content {
  text-align: center;
  z-index: 2;
  padding: 0 2rem;
  animation: fadeIn 1s ease-out;
  margin-top: 4rem;
}

.hero-title-container {
  position: relative;
  margin-bottom: 2rem;
}

.hero-title {
  font-size: 7rem;
  font-weight: 800;
  background: linear-gradient(45deg, var(--primary-color), #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 2;
  animation: glow 2s ease-in-out infinite alternate;
}

.title-glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(30px);
  opacity: 0.5;
  background: var(--primary-color);
  animation: titlePulse 2s infinite alternate;
}

.hero-subtitle {
  font-size: 2.2rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  opacity: 0.9;
  font-weight: 300;
}

.hero-cta {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 3rem;
}

.cta-button {
  padding: 1.2rem 2.8rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  position: relative;
  overflow: hidden;
}

.cta-button.primary {
  background: var(--gradient-1);
  color: white;
  box-shadow: 0 4px 15px rgba(97, 218, 251, 0.3);
}

.cta-button.secondary {
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.button-glow {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(97, 218, 251, 0.4) 0%,
    transparent 70%
  );
  animation: buttonGlow 3s infinite;
}

.button-pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(97, 218, 251, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: buttonPulse 2s infinite;
}

.hero-stats {
  display: flex;
  gap: 4rem;
  z-index: 2;
  margin-top: 2rem;
}

.stat-item {
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  padding: 2rem 3rem;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(97, 218, 251, 0.2);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.stat-number {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
}

.stat-glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: radial-gradient(
    circle at center,
    rgba(97, 218, 251, 0.2) 0%,
    transparent 70%
  );
  transition: opacity 0.3s ease;
}

.stat-item:hover .stat-glow {
  opacity: 1;
}

.floating-particles {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(97, 218, 251, 0.3);
  border-radius: 50%;
  animation: float 20s infinite linear;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes glow {
  from { text-shadow: 0 0 20px rgba(97, 218, 251, 0.5); }
  to { text-shadow: 0 0 30px rgba(97, 218, 251, 0.8); }
}

@keyframes titlePulse {
  0% { opacity: 0.3; transform: scale(0.8); }
  100% { opacity: 0.6; transform: scale(1.2); }
}

@keyframes buttonGlow {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes buttonPulse {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 0.5; }
  100% { transform: translate(-50%, -50%) scale(2); opacity: 0; }
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(-100vh) translateX(100vw);
    opacity: 0;
  }
}

.particle:nth-child(1) { left: 10%; top: 20%; animation-delay: 0s; }
.particle:nth-child(2) { left: 20%; top: 40%; animation-delay: 2s; }
.particle:nth-child(3) { left: 30%; top: 60%; animation-delay: 4s; }
.particle:nth-child(4) { left: 40%; top: 80%; animation-delay: 6s; }
.particle:nth-child(5) { left: 50%; top: 20%; animation-delay: 8s; }
.particle:nth-child(6) { left: 60%; top: 40%; animation-delay: 10s; }
.particle:nth-child(7) { left: 70%; top: 60%; animation-delay: 12s; }
.particle:nth-child(8) { left: 80%; top: 80%; animation-delay: 14s; }
.particle:nth-child(9) { left: 90%; top: 20%; animation-delay: 16s; }
.particle:nth-child(10) { left: 15%; top: 40%; animation-delay: 18s; }

@media (max-width: 768px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-subtitle {
    font-size: 1.6rem;
  }

  .hero-stats {
    flex-direction: column;
    gap: 1.5rem;
  }

  .stat-item {
    padding: 1.5rem 2rem;
  }

  .glow-effect {
    width: 300px;
    height: 300px;
  }

  .hero-cta {
    flex-direction: column;
    gap: 1rem;
  }
}

.info-section {
  padding: 6rem 2rem;
  position: relative;
  z-index: 2;
  background: transparent;
}

.mission-card {
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  background: transparent;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.card-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.mission-card h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, var(--primary-color), #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mission-card p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .info-section {
    padding: 4rem 1rem;
  }

  .mission-card h2 {
    font-size: 2rem;
  }

  .mission-card p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
}