.dashboard-container {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(97, 218, 251, 0.2);
  margin: 2rem;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.filter-controls {
  display: flex;
  gap: 1rem;
}

.filter-controls button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background: transparent;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-controls button.active {
  background: var(--primary-color);
  color: var(--secondary-color);
}

.facilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.facility-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
  padding: 1.5rem;
  border: 1px solid rgba(97, 218, 251, 0.1);
  transition: all 0.3s ease;
}

.facility-card:hover {
  transform: translateY(-5px);
  border-color: var(--primary-color);
}

.occupancy-meter {
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin: 1rem 0;
  overflow: hidden;
}

.occupancy-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.facility-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
}

.status-badge.high { background: var(--red); }
.status-badge.moderate { background: var(--yellow); }
.status-badge.low { background: var(--green); }

@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    gap: 1rem;
  }

  .facilities-grid {
    grid-template-columns: 1fr;
  }
}