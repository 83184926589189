.facility-detail-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.current-status {
  text-align: center;
  height: 60%;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  margin-top: 7rem;
  position: relative;
}

.capacity {
  text-align: center;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 0.5rem;
}

.facility-info h3 {
  margin-bottom: 1rem;
}

.facility-recommendations h3 {
  margin-bottom: 1rem;
}

.modal-content {
  background: var(--secondary-color);
  border-radius: 20px;
  margin-top: 60px;
  padding: 1.5rem 2rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border: 1px solid rgba(97, 218, 251, 0.2);
  position: relative;
  margin-top: 150px;
  padding-top: 0.5rem;
  position: relative;
}

h2 {
  color: var(--text-color);
  font-size: 1.5rem;
  padding-top: 10rem;
  margin-bottom: -8rem;
  text-align: center;
}

.occupancy-info {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}

.current-status {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
}

.percentage {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary-color);
}

.prediction-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.prediction-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 15px;
  text-align: center;
}

.prediction-value {
  font-size: 2rem;
  color: var(--primary-color);
  margin: 0.5rem 0;
}

.prediction-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.chart-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 15px;
  height: 300px;
}

.weekly-pattern {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 15px;
}

.weekly-pattern h3 {
  margin-bottom: 1rem;
}

.day-bars {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 200px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  position: relative;
}

.day-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  height: 100%;
  position: relative;
}

.bar-fill {
  width: 100%;
  position: absolute;
  bottom: 40px;
  border-radius: 4px 4px 0 0;
  background: var(--primary-color);
  transition: height 0.3s ease;
}

.day-label {
  position: absolute;
  bottom: 0;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
}

.bar-label {
  position: absolute;
  top: -25px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
}

.time-slots {
  display: grid;
  gap: 1rem;
}

.time-slot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.occupancy-level {
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.9rem;
}

.occupancy-level.low {
  background: #4caf50;
}

.occupancy-level.moderate {
  background: #ffd700;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.info-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 10px;
}

.info-item .label {
  display: block;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.info-item .value {
  font-size: 1.1rem;
  color: var(--primary-color);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.occupancy-trend {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 300px;
  padding: 3rem 2rem 4rem 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  position: relative;
  margin-top: 1rem;
}

.hour-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45px;
  height: 100%;
  padding-top: 30px;
  position: relative;
  margin: 0 5px;
}

.bar-label {
  position: absolute;
  top: -40px;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

.hour-bar .bar-fill {
  width: 100%;
  position: absolute;
  bottom: 25px;
  border-radius: 4px 4px 0 0;
  transition: height 0.3s ease;
}

.hour-label {
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .hour-bar {
    width: 30px;
    margin: 0 2px;
  }

  .bar-label, .hour-label {
    font-size: 0.8rem;
  }

  .occupancy-trend {
    height: 200px;
    padding: 2rem 1rem 3rem 1rem;
  }
}

.floating-close {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 36px;
  height: 36px;
  background: #61dafb;
  border: none;
  border-radius: 50%;
  color: #282c34;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
  padding-bottom: 4px;
}

.floating-close:hover {
  transform: scale(1.1);
  background: #7ae2ff;
}
