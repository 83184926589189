:root {
  --primary-color: #61dafb;
  --secondary-color: #282c34;
  --text-color: #ffffff;
  --gradient-1: linear-gradient(135deg, #61dafb 0%, #0088cc 100%);
  --gradient-2: linear-gradient(135deg, #282c34 0%, #1a1a1a 100%);
  --gradient-3: linear-gradient(45deg, rgba(97, 218, 251, 0.1) 0%, rgba(0, 136, 204, 0.1) 100%);
}

body {
  margin: 0;
  padding: 0;
  background: var(--gradient-2);
  color: var(--text-color);
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container {
  flex: 1;
  padding-top: 60px;
}