.navbar {
  background: rgba(26, 26, 26, 0.95);
  backdrop-filter: blur(10px);
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.navbar-brand h1 {
  font-size: 2.5rem;
  font-weight: 700;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.navbar-menu {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.navbar-menu button,
.signin-button {
  all: unset;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  height: 38px;
  min-width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.navbar-menu button:hover,
.signin-button:hover {
  background: var(--primary-color);
  color: var(--secondary-color);
  transform: translateY(-2px);
}

/* Remove all !important since we're using the same base styles */
.signin-button {
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-brand h1 {
    font-size: 2rem;
  }

  .navbar-menu button,
  .signin-button {
    padding: 6px 18px;
    font-size: 1rem;
    min-width: 90px;
    height: 36px;
  }
}